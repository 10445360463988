const COLORS = {
    white: '#FFFFFF',
    black: '#000000',
    white70: '#FFFFFFB8',
    secondary: '#05B49B'
}

const FONTS = {
    SFPRODISPLAY: 'SFPRODISPLAY, sans-serif',
};


export {
    COLORS,
    FONTS
}